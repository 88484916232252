/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "jp-klauc",
    "appId": "1:907907487939:web:5ee7b431fdc77f3f356a76",
    "storageBucket": "jp-klauc.appspot.com",
    "apiKey": "AIzaSyBFF-z1kwvimWccG-VC0-su3jMODDk8W5o",
    "authDomain": "jp-klauc.firebaseapp.com",
    "messagingSenderId": "907907487939"
  }
};
